const team = {
    title: 'Our purpose',
    header: 'Our purpose',
    description:
        'We work with our customers to understand their business, their needs, and ultimately, their goals. ',
    paragraph:
        'As a company, we want to make software and technology approachable and fun for those who may not completely understand software or might be intimidated by the complexity of the software industry.',
}

export default team
