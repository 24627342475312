const team = {
    title: 'The team',
    header: 'The team',
    paragraph1:
        'As a group, we are lighthearted and easy to work with, and we try to not take ourselves too seriously. With that said, we still pride ourselves with quality work and quality deliverables, from the code itself, to the documentation.',
    paragraph2:
        'We like to make connections with our customers instead of treating the relationship as strictly a business relationship.',
}

export default team
