import homepage from './homepage'
import purpose from './purpose'
import expertise from './expertise'
import team from './team'
import contact from './contact'
import error404 from './error404'
import thankYou from './thankYou'

const en = {
    homepage,
    purpose,
    expertise,
    team,
    contact,
    error404,
    thankYou,
}

export default en
