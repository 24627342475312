const expertise = {
    title: 'Our expertise',
    header: 'Our expertise',
    webapp: 'Web app development',
    backend: 'Backend development & design',
    cloud: 'Google Cloud implementations',
    database: 'Database design & setup',
    data: 'Data and analytics',
}

export default expertise
