const contact = {
    title: 'Contact us',
    header: "Let's have a conversation",
    paragraph:
        "Let's work together. Our form will help us get in touch and answer any questions you may have.",
    name: 'Name',
    email: 'Email',
    company: 'Company',
    message: 'Message',
    send: 'Send',
    contactUs: 'Contact us',
    siteBy: 'Website by',
}

export default contact
